var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white px-1 rounded-lg"},[_c('h2',{staticClass:"font-weight-bolder font-medium-5 pt-2"},[_vm._v(" "+_vm._s(_vm.$t('Order Items'))+" ")]),_c('span',[_c('scan',{attrs:{"asset-item-serial-numbers":_vm.assetItemSerialNumbers,"scan-text":"To start the put back process, press inspect or uncheck the items not to be inspected."},on:{"getValue":_vm.handleCheckBySerialNumber}}),_c('l-table-list-collector',{ref:"lTableRef",attrs:{"table-columns":_vm.putBackOrderItemsTableColumns,"module-name":_vm.MODULE_NAME,"fetched-data":_vm.orderItems,"isSearchable":false,"row-active":{
        filedKey: 'inspected_inbound',
        classes: 'highlightRow'
      }},scopedSlots:_vm._u([{key:"cell(state)",fn:function(ref){
      var item = ref.data.item;
return [_c('state-of-asset',{attrs:{"data":item,"with-label":false,"styles":{ color: '#00B139', width: '18px', height: '18px' }}})]}},{key:"cell(sku)",fn:function(ref){
      var item = ref.data.item;
return [_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'product.sku', '—'))+" ")]}},{key:"cell(location)",fn:function(ref){
      var item = ref.data.item;
return [_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'location.level_name', '—'))+" ")]}},{key:"cell(name)",fn:function(ref){
      var item = ref.data.item;
return [_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'product.name', '—'))+" ")]}},{key:"cell(asset_id)",fn:function(ref){
      var item = ref.data.item;
return [_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'asset_id', '—'))+" ")]}},{key:"cell(inspected)",fn:function(ref){
      var item = ref.data.item;
return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.status.toString() !== _vm.assetStates.MISSING.code.toString()),expression:"item.status.toString() !== assetStates.MISSING.code.toString()"}],staticClass:"text-center d-flex justify-content-between align-items-center"},[_c('b-form-checkbox',{attrs:{"checked":!!item.inspected_inbound},on:{"change":function($event){return _vm.handleUpdateItem(item.id, 'inspected_inbound')}}}),_c('feather-icon',{class:("icon-wrapper " + ((item.status.toString() === _vm.assetStates.SERVICE.code.toString() || item.put_backed_at || !item.inspected_inbound) && 'disabled')),attrs:{"icon":"LWarningGreyIcon","size":"25"},on:{"click":function($event){return _vm.createServiceItem(item)}}})],1)]}},{key:"cell(put_back)",fn:function(ref){
      var item = ref.data.item;
return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.status.toString() !== _vm.assetStates.MISSING.code.toString()),expression:"item.status.toString() !== assetStates.MISSING.code.toString()"}],staticClass:"text-center"},[_c('b-form-checkbox',{attrs:{"checked":!!item.put_backed_at,"disabled":!item.inspected_inbound},on:{"change":function($event){return _vm.handleUpdateItem(item.id, 'put_backed_at')}}})],1)]}}])}),_c('change-asset-to-damaged',{ref:"change-asset-to-damaged",attrs:{"fulfilment-order":""},on:{"orderDamagedReported":_vm.orderDamagedReported}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }