<template>
  <div class="bg-white px-1 rounded-lg">
    <span class="font-weight-bolder font-medium-5 d-block">
      {{ $t('Order Information') }}
    </span>
    <b-row class="mt-1 pb-1 ml-0">
      <b-col
        cols="6"
      >
        <div
          v-if="isSubRentOrder || isPurchaseCrOrder"
          class="orderInfo"
        >
          <b-row
            v-for="(item) in orderInformationRight"
            :key="item.key"
            class="d-flex align-items-center orderInfoItem"
          >
            <b-col
              cols="4"
              class="font-weight-bolder m-0"
            >
              {{ item.label }}
            </b-col>
            <b-col
              cols="8"
              class="m-0"
            >
              {{ getValueFromGivenObjectByKey(orderItem, item.key) }}
            </b-col>
          </b-row>
        </div>
        <component
          :is="ServiceOrderPickFields[field].type"
          v-for="field of ['warehouseNotes']"
          :key="field"
          v-model="orderItem.warehouse_notes"
          style="width: 100%"
          v-bind="getProps(field, ServiceOrderPickFields)"
        />
      </b-col>
      <b-col
        cols="6"
      >
        <div
          v-if="isSubRentOrder || isPurchaseCrOrder"
          class="orderInfo"
        >
          <b-row
            v-for="(item) in orderInformationLeft"
            :key="item.key"
            class="d-flex align-items-center orderInfoItem"
          >
            <b-col
              cols="4"
              class="font-weight-bolder m-0"
            >
              {{ item.label }}
            </b-col>
            <b-col
              cols="8"
              class="m-0"
            >
              {{ getValueFromGivenObjectByKey(orderItem, item.key) }}
            </b-col>
          </b-row>
          <component
            :is="orderInfoFields[field].type"
            v-for="field of ['internal_order_reference','internal_shipping_notes','internal_account_notes']"
            :key="field"
            v-model="orderItem[field]"
            style="width: 100%"
            is-editable
            v-bind="getProps(field, orderInfoFields)"
          />
        </div>
        <div v-if="!isSubRentOrder && !isPurchaseCrOrder">
          <component
            :is="ServiceOrderPickFields[field].type"
            v-for="field of ['orderNotes']"
            :key="field"
            v-model="orderItem.internal_notes"
            style="width: 100%"
            is-editable
            v-bind="getProps(field, ServiceOrderPickFields)"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import config from '@/views/main/warehouse/view/receive/config'
import { getProps, getValueFromGivenObjectByKey } from '@core/utils/utils'
import mainOrderConfig from '@/views/main/warehouse/main-order/config'

export default {
  name: 'OrderInformation',
  components: {
    BRow,
    BCol,
  },
  data() {
    return {
      orderType: this.$route.params.orderType.toUpperCase(),
    }
  },
  computed: {
    orderItem() {
      return this.$store.state[this.MODULE_NAME].orderItem
    },
    isSubRentOrder() {
      return this.orderType === this.ORDER_TYPES_KV.SR
    },
    isPurchaseCrOrder() {
      return this.orderType === this.ORDER_TYPES_KV.PO
    },
  },
  methods: { getProps },
  setup() {
    const {
      MODULE_NAME, ServiceOrderPickFields, orderInformationRight, orderInformationLeft, orderInfoFields,
    } = config()
    const { ORDER_TYPES_KV } = mainOrderConfig()

    return {
      MODULE_NAME, getValueFromGivenObjectByKey, ServiceOrderPickFields, ORDER_TYPES_KV, orderInformationRight, orderInformationLeft, orderInfoFields,
    }
  },
}
</script>

<style scoped lang="scss">
.orderInfo{
  display: flex;
  flex-direction: column;
  gap: 10px;
  .orderInfoItem{
    &:last-child{
      margin-bottom: 15px;
    }
  }
}
</style>
