<template>
  <div
    class="bg-white mb-1"
    style="border-radius: 5px; padding: 12px; box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.05);"
  >
    <b-button
      v-if="!hiddeSomeBlocksByOrderType"
      variant="secondary"
      :class="`${tabActive && 'bg-D9D9D9'} font-weight-bolder`"
      @click="tabActive = 0"
    >
      {{ $t('By Container') }}
    </b-button>
    <b-button
      v-if="!hiddeSomeBlocksByOrderType"
      variant="secondary"
      :class="`${!tabActive && 'bg-D9D9D9'} ml-1`"
      @click="tabActive = 1"
    >
      {{ $t('As Ordered') }}
    </b-button>
    <h3 class="font-weight-bolder text-body mt-1">
      {{ `${hiddeSomeBlocksByOrderType ? 'Items' : 'Rental Items'}` }}
    </h3>
    <scan
      v-if="hasScan"
      style="padding:0 0 12px"
      :scan-text="scanText"
      :asset-item-serial-numbers="assetItemSerialNumbers"
      @getValue="handleCheckBySerialNumber"
    />
    <by-container
      v-if="!tabActive"
      :order-item="orderItems"
      :selected-receiving="hasScan"
      @getValue="handleCheckBySerialNumber"
      @reportedAsset="reportedAsset"
    />
    <as-ordered
      v-if="tabActive"
      :selected-receiving="hasScan"
      :order-item="orderItems"
    />
  </div>
</template>
<script>

import { BButton } from 'bootstrap-vue'
import config from '@/views/main/warehouse/view/dispatch/dispatchConfig'
import mainOrderConfig from '@/views/main/warehouse/main-order/config'
import Scan from '@/views/components/Scan/Scan.vue'
import map from 'lodash/map'
import ByContainer from '../../../../main-order/components/tables/ByContainer.vue'
import AsOrdered from '../../../../main-order/components/tables/AsOrdered.vue'

export default {
  components: {
    Scan,
    BButton,
    ByContainer,
    AsOrdered,
  },
  props: {
    orderItems: {
      type: Array,
      default: () => [],
    },
    hasScan: {
      type: Boolean,
      default: false,
    },
    scanText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tabActive: 0,
      orderType: this.$route.params.orderType,
    }
  },
  computed: {
    assetItemSerialNumbers() {
      return map(this.orderItems, item => `${item?.inventory_item?.serial_number}_${item?.product?.sku}`)
    },
    hiddeSomeBlocksByOrderType() {
      const paramOrderType = this.orderType.toLowerCase()
      return paramOrderType === this.ORDER_TYPES_KV.SM.toLowerCase() || paramOrderType === this.ORDER_TYPES_KV.TO.toLowerCase()
    },
  },
  methods: {
    handleCheckBySerialNumber(serialNumber) {
      this.$emit('handleCheckBySerialNumber', serialNumber)
    },
    reportedAsset() {
      this.$emit('reportedAsset')
    },
  },
  setup() {
    const { MODULE_NAME } = config()
    const { ORDER_TYPES_KV } = mainOrderConfig()

    return { MODULE_NAME, ORDER_TYPES_KV }
  },
}
</script>
<style scoped lang="scss">
.bg-D9D9D9 {
  background-color: #d9d9d9 !important;
  color: #646464 !important;
}
</style>
