<template>
  <div>
    <portal to="body-top">
      <h3 class="text-center font-weight-bolder mb-1">
        {{ $t(`Receiving Order ${orderType}-${id}`) }}
      </h3>
    </portal>
    <skeleton v-if="isLoading" />
    <receive-form v-if="!isLoading && orderItem" />
  </div>
</template>
<script>

import config from '@/views/main/warehouse/view/receive/config'
import ReceiveForm from '@/views/main/warehouse/view/receive/components/InBoundForm.vue'
import Skeleton from '@/views/main/warehouse/view/pick/rental-sales/components/Skeleton.vue'
import store from '@/store'
import receiveModule from '@/store/main/warehouse/inbound/index'
import { onUnmounted } from '@vue/composition-api'
import inventoryModule from '@/store/main/inventory'

export default {
  name: 'ReceiveOrder',
  components: { Skeleton, ReceiveForm },
  data() {
    return {
      id: this.$route.params.id,
      orderType: this.$route.params.orderType,
    }
  },
  computed: {
    isLoading() {
      return this.$store.state[this.MODULE_NAME].isLoading
    },
    orderItem() {
      return this.$store.state[this.MODULE_NAME].orderItem
    },
  },
  created() {
    this.getOrderItem(this)
  },
  setup() {
    const {
      MODULE_NAME,
      getOrderItem, INVENTORIES_MODULE_NAME,
    } = config()

    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, receiveModule)
    if (!store.hasModule(INVENTORIES_MODULE_NAME)) store.registerModule(INVENTORIES_MODULE_NAME, inventoryModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
      if (store.hasModule(INVENTORIES_MODULE_NAME)) store.unregisterModule(INVENTORIES_MODULE_NAME)
    })

    return {
      MODULE_NAME,
      getOrderItem,
    }
  },
}
</script>
