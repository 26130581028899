<template>
  <div class="mt-1 d-flex ">
    <div class="bg-white rounded w-50 mr-1 p-1">
      <h1 class="font-weight-bolder font-medium-5">
        {{ 'Incoming Assets' }}
      </h1>

      <div
        v-for="[key,values] in Object.entries(incomingAssets)"
        :key="key"
      >
        <h5 class="font-weight-bolder ">
          {{ key }}
        </h5>
        <l-table-list-collector
          :ref="`${key}_lTableRef`"
          :is-searchable="false"
          :module-name="MODULE_NAME"
          :table-columns="unpackOrderTableColumns"
          :fetched-data="values"
          class="tableIncomingItems"
          :has-footer="false"
          show-empty-list-title
        >
          <template #cell(state)="{data: {item}}">
            <state-of-asset
              :data="item"
              :with-label="false"
              :styles="{ color: '#00B139', width: '18px', height: '18px' }"
            />
          </template>
          <template #cell(id)="{data: {item}}">

            <div class="d-flex align-items-baseline justify-content-between">

              <span>{{ getValueFromGivenObjectByKey(item,'asset_id') }}</span>
              <div
                v-show="item.type_id === 3"
                class="icon-wrapper active"
              >
                <feather-icon
                  icon="LBoxIcon"
                  size="16"
                />
              </div>
            </div>

          </template>
          <template #cell(name)="{data: {item}}">
            {{ getValueFromGivenObjectByKey(item,'product.name') }}
          </template>
          <template #cell(actions)="{data: {item}}">
            <div
              class="d-flex align-items-baseline justify-content-between"
              style="gap: 10px"
            >
              <feather-icon
                class="icon-wrapper"
                icon="LUnPuckIcon"
                size="28"
                @click="unPackAsset(item)"
              />
              <feather-icon
                class="icon-wrapper"
                icon="LWarningGreyIcon"
                size="28"
                @click="showReportModal(item.id)"
              />
              <report-modal
                :order-item="item"
                @reportedAsset="reportedAsset"
              />
            </div>
          </template>
        </l-table-list-collector>

      </div>
    </div>
    <div
      class="bg-white rounded w-50"
    >
      <h1 class="font-weight-bolder font-medium-5 m-1">
        {{ 'Received Assets' }}
      </h1>

      <scan
        scan-text="Scan the items while you unpack them."
        style="padding:0 12px 12px"
        :asset-item-serial-numbers="assetItemSerialNumbers"
        @getValue="handleScan"
      />
      <div>
        <div class="wrapper">
          <div class="header">
            <p
              v-for="header in unpackOrderTableColumns"
              :key="header.key"
              :style="header.thStyle"
              class="header-columns m-0"
            >
              {{ header.label }}
            </p>
          </div>
          <div
            class="scannedContainers"
          >
            <div
              v-for="(item, containerIndex) in receiveAssets"
              :key="containerIndex"
              class="bodyContainer"
            >
              <div class="containerWrapper">
                <div class="mainInfo container">
                  <div
                    style="width: 8%"
                  >
                    <state-of-asset
                      :data="item"
                      :with-label="false"
                      :styles="{ color: '#00B139', width: '18px', height: '18px' }"
                    />
                  </div>
                  <div
                    style="width: 18%"
                    class="d-flex align-items-center justify-content-between"
                  >
                    <p>
                      {{ getValueFromGivenObjectByKey(item, 'asset_id') }}
                    </p>
                    <div
                      v-show="item.type_id === 3"
                      class="icon-wrapper active"
                    >
                      <feather-icon
                        icon="LBoxIcon"
                        size="18"
                      />
                    </div>
                  </div>
                  <p>
                    {{ getValueFromGivenObjectByKey(item, 'product.name') }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="text-right mr-1 font-weight-bolder">
            Total assets received {{ receiveAssets.filter((i) => i.status.toString() === assetStates.RECEIVED.code.toString()).length }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/views/main/warehouse/view/receive/config'
import mainOrderConfig from '@/views/main/warehouse/main-order/config'
import StateOfAsset from '@/views/main/inventory/inventory-view/components/StateOfAsset.vue'
import LTableListCollector from '@/views/components/LTableListCollector.vue'
import * as _ from 'lodash'
import { getValueFromGivenObjectByKey } from '@core/utils/utils'
import Scan from '@/views/components/Scan/Scan.vue'
import ReportModal from '@/views/main/warehouse/view/dispatch/components/rental-items/ReportModal.vue'
import { assetStates } from '@/enum/asset-statuses'

export default {
  name: 'UnPackItems',
  components: {
    ReportModal,
    Scan,
    LTableListCollector,
    StateOfAsset,
  },
  props: {
    orderItems: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    receiveAssets() {
      return this.orderItems.filter(item => item.received_at)
    },
    incomingAssets() {
      return _.groupBy(this.orderItems.filter(item => !item.received_at), item => item.product.categories.map(category => category.name).join(', '))
    },
    assetItemSerialNumbers() {
      return this.orderItems.map(item => `${item?.inventory_item?.serial_number}_${item?.product?.sku}`)
    },
  },
  methods: {
    unPackAsset(item) {
      this.$emit('handleCheckBySerialNumber', item?.inventory_item?.serial_number || item?.product?.sku)
    },
    handleScan(value) {
      this.$emit('handleCheckBySerialNumber', value)
    },
    showReportModal(item) {
      this.$bvModal.show(`report-warning-modal-${item}`)
    },
    reportedAsset() {
      this.$emit('reportedAsset')
    },
  },
  setup() {
    const {
      MODULE_NAME,
      unpackOrderTableColumns,
    } = config()
    const { orderPropsByType, ORDER_TYPES_KV } = mainOrderConfig()

    return {
      MODULE_NAME,
      orderPropsByType,
      ORDER_TYPES_KV,
      unpackOrderTableColumns,
      getValueFromGivenObjectByKey,
      assetStates,
    }
  },
}
</script>

<style lang="scss">
.tableIncomingItems{
  td,th{
    padding: 0.5rem 0.6rem;
  }

}

.icon-wrapper {
  padding: 2px 5px;
  background: rgba(243, 243, 243, 1);
  border: 1px solid rgba(221, 227, 231, 1);
  border-radius: 5px;
  cursor: pointer;
  width: min-content;
  & > .feather {
    width: unset;
    height: unset;
  }
  path{
    fill: #646464;
  }
  &.active {
    background: #646464;

    path{
      fill: #fff;
    }
  }
}
.scanInput {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: none;
}

.disabledRemoveIcon {
  cursor: not-allowed;
  opacity: 0.5;
}

.rowAssetItems{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.wrapper {
  padding:0 12px 12px;
}

.header-columns {
  font-weight: bold;
  font-size: 16px;
  color: rgba(100, 100, 100, 1);
}

.header {
  display: flex;
  gap: 12px;
}

.packScan {
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  width: 100%;
  padding: 7px 12px;

  &:focus-visible {
    outline: none;
  }
}

.scannedContainers {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.bodyContainer {
  display: flex;
  flex-direction: column;
  min-height: 35px;
  //padding: 7px 12px;
  border: 1px solid rgba(221, 227, 231, 1);
  background: rgba(0, 177, 57, 0.05);
  border-radius: 5px;
}

.containerWrapper {
  display: flex;
  align-items: center;
}

.container {
  background: transparent !important;
  border: none !important;
}

.mainInfo {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  background: rgba(0, 177, 57, 0.15);
  border: 1px solid rgba(221, 227, 231, 1);
  border-radius: 5px;
  padding: 2px 9px;

  & p {
    margin: 0;
  }
}

</style>
