import mainConfig from '@/views/main/warehouse/main-order/config'
import { TEXTAREA_INPUT } from '@/views/components/DynamicForm/constants'

export default function config() {
  const MODULE_NAME = 'inbound-orders'
  const INVENTORIES_MODULE_NAME = 'inventories'

  const getOrderItem = ctx => {
    const orderPropsByType = mainConfig().orderPropsByType(ctx.$route.params.orderType)
    const params = {
      id: ctx.$route.params.id,
      orderEndpoint: orderPropsByType.urlAction,
    }
    ctx.$store.commit(`${MODULE_NAME}/SET_LOADING`, true)
    ctx.$store.dispatch(`${MODULE_NAME}/orderItem`, params).finally(() => {
      ctx.$store.commit(`${MODULE_NAME}/SET_LOADING`, false)
    })
  }

  const ServiceOrderPickFields = {
    warehouseNotes: {
      type: TEXTAREA_INPUT,
      label: 'Warehouse Notes',
      placeholder: 'Write notes here...',
      options: {
        cols: '12',
      },
    },
    orderNotes: {
      type: TEXTAREA_INPUT,
      label: 'Order Notes',
      placeholder: 'User 1 @ Sep 10, 2022: Some order notes.',
      options: {
        cols: '12',
      },
    },
  }

  const orderInfoFields = {
    internal_order_reference: {
      type: TEXTAREA_INPUT,
      label: 'Internal RFQ Notes',
      placeholder: '',
      options: {
        cols: '12',
      },
    },
    internal_shipping_notes: {
      type: TEXTAREA_INPUT,
      label: 'External RFQ Notes (visible to the supplier)',
      placeholder: '',
      options: {
        cols: '12',
      },
    },
    internal_account_notes: {
      type: TEXTAREA_INPUT,
      label: 'External PO Notes (visible to the supplier)',
      placeholder: '',
      options: {
        cols: '12',
      },
    },
  }

  const unpackOrderTableColumns = [
    {
      key: 'state',
      label: 'State',
      thStyle: { width: '8%', padding: '5px 7px' },
    },
    {
      key: 'id',
      label: 'Id',
      thStyle: { width: '19%', padding: '5px 7px' },
    },
    {
      key: 'name',
      label: 'Name',
      thStyle: { width: '78%', padding: '5px 7px' },
    },
    {
      key: 'actions',
      label: '',
    },
  ]

  const putBackOrderItemsTableColumns = [
    {
      key: 'state',
      label: '',
      thStyle: { width: '0%' },
    },
    {
      key: 'asset_id',
      label: 'Asset ID',
      thStyle: { width: '11%' },
    },
    {
      key: 'name',
      label: 'Name',
      thStyle: { width: '30%' },
    },
    {
      key: 'sku',
      label: 'Sku',
      thStyle: { width: '7%' },
    },
    {
      key: 'inspected',
      label: 'Inspected',
      thStyle: { width: '19%' },
    },
    {
      key: 'location',
      label: 'Location',
      thStyle: { width: '15%' },
    },
    {
      key: 'put_back',
      label: 'Put Back',
      thStyle: { width: '14%' },
    },
  ]

  const orderInformationRight = [
    {
      label: 'Supplier',
      key: 'supplier',
    },
    {
      label: 'Required by',
      key: 'required_by',
    },
    {
      label: 'Billing Contact',
      key: 'return_contact.fullName',
    },
    {
      label: 'Billing Phone',
      key: 'return_contact.phone_one',
    },
    {
      label: 'Billing Email',
      key: 'return_contact.email',
    },
    {
      label: 'Billing Address',
      key: 'return_warehouse.label',
    },
  ]

  const orderInformationLeft = [
    {
      label: 'Dispatch Warehouse',
      key: 'dispatch_warehouse.label',
    },
    {
      label: 'Dispatch Method',
      key: 'dispatch_method.name',
    },
    {
      label: 'Dispatch Contact',
      key: 'dispatch_contact.fullName',
    },
    {
      label: 'Dispatch Phone',
      key: 'dispatch_contact.phone_one',
    },
    {
      label: 'Dispatch Email',
      key: 'dispatch_contact.email',
    },
    {
      label: 'Shipping Address',
      key: 'dispatch_address.city',
    },
  ]

  return {
    MODULE_NAME,
    INVENTORIES_MODULE_NAME,
    getOrderItem,
    ServiceOrderPickFields,
    unpackOrderTableColumns,
    putBackOrderItemsTableColumns,
    orderInformationRight,
    orderInformationLeft,
    orderInfoFields,
  }
}
