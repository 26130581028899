<template>
  <div>
    <quote-status-bar
      :quote-status-bar="quoteStatusBarItems"
      :item="quoteStatusItem"
      class="mb-1"
    />
    <order-exception-status
      :item="orderItem"
    />
    <in-bound-form-body-info />
    <order-information class="mb-1" />
    <rental-items
      v-if="dispatchStatus.conditioned"
      :order-items="orderItems"
    />
    <rental-items
      v-if="receiveStatus.conditioned"
      has-scan
      :order-items="orderItems"
      scan-text="Scan the containers, and non-contained assets to receive them."
      @handleCheckBySerialNumber="handleCheckBySerialNumber"
      @reportedAsset="reportedAsset"
    />
    <un-pack-items
      v-if="unpackStatus.conditioned"
      :order-items="orderItems"
      @reportedAsset="reportedAsset"
      @handleCheckBySerialNumber="handleCheckBySerialNumber"
    />

    <order-items
      v-if="finishedStatus.conditioned || putBackStatus.conditioned"
      :order-items="orderItems"
      @updateItem="updateItem"
      @orderDamagedReported="reportedAsset"
    />

    <in-bound-form-bottom :btn-props="btnProps" />
  </div>
</template>
<script>
import config from '@/views/main/warehouse/view/receive/config'
import RentalItems from '@/views/main/warehouse/view/dispatch/components/rental-items/RentalItems.vue'
import OrderExceptionStatus
from '@/views/main/warehouse/main-order/components/order-exception-status/OrderExceptionStatus.vue'
import QuoteStatusBar from '@/views/main/orders/components/use-as-global/QuoteStatusBar.vue'
import warehouseConfig from '@/views/main/warehouse/config'
import * as _ from 'lodash'
import mainOrderConfig from '@/views/main/warehouse/main-order/config'
import InBoundFormBottom from './InBoundFormBottom.vue'
import OrderInformation from './OrderInformation.vue'
import InBoundFormBodyInfo from './InBoundFormBodyInfo.vue'
import UnPackItems from './UnPackItems.vue'
import OrderItems from './OrderItems.vue'

export default {
  name: 'ReceiveForm',
  components: {
    InBoundFormBodyInfo,
    OrderInformation,
    InBoundFormBottom,
    QuoteStatusBar,
    OrderExceptionStatus,
    RentalItems,
    UnPackItems,
    OrderItems,
  },
  data() {
    return {
      orderType: this.$route.params.orderType,
    }
  },
  computed: {
    orderItem() {
      return this.$store.state[this.MODULE_NAME].orderItem
    },
    orderItems() {
      return _.filter(this.orderItem.order_items, item => (item?.rental_sale_type ? item.rental_sale_type === 'rent' : true))
    },
    quoteStatusItem() {
      return this.orderItem && {
        state: this.orderItem.fulfillment_state,
        status: this.orderItem.fulfillment_status,
      }
    },
    quoteStatusBarItems() {
      return this.quoteStatusItemsMerge(this.orderItem.states, true)
    },
    dispatchStatus() {
      const dispatchConditions = [
        ([this.ORDER_TYPES_KV.SR, this.ORDER_TYPES_KV.PO].includes(this.orderType) && this.quoteStatusItem.state === 5),
        ([this.ORDER_TYPES_KV.RO, this.ORDER_TYPES_KV.HO, this.ORDER_TYPES_KV.SO].includes(this.orderType) && this.quoteStatusItem.state === 6),
        ([this.ORDER_TYPES_KV.SM, this.ORDER_TYPES_KV.TO].includes(this.orderType) && this.quoteStatusItem.state === 7),
      ]
      return {
        conditioned: dispatchConditions.some(Boolean) || (!this.quoteStatusItem.state && !this.quoteStatusItem.status),
        btnProps: {
          text: 'Receive',
          icon: 'LWarehouseIcon',
          action: 'receive',
        },
      }
    },
    receiveStatus() {
      const receiveInProgressConditions = [
        ([this.ORDER_TYPES_KV.SR, this.ORDER_TYPES_KV.PO].includes(this.orderType) && this.quoteStatusItem.state === 6),
        ([this.ORDER_TYPES_KV.RO, this.ORDER_TYPES_KV.HO, this.ORDER_TYPES_KV.SO].includes(this.orderType) && this.quoteStatusItem.state === 7),
        ([this.ORDER_TYPES_KV.SM, this.ORDER_TYPES_KV.TO].includes(this.orderType) && this.quoteStatusItem.state === 8),
      ]
      return {
        conditioned: receiveInProgressConditions.some(Boolean),
        btnProps: {
          text: 'Unpack',
          icon: 'LUnPuckIcon',
          action: 'unpack',
          disabled: !this.orderItems.some(item => (item.type_id === 3 || !item.parent_id) && item.received_at),
        },
      }
    },
    unpackStatus() {
      const unpackInProgressConditions = [
        ([this.ORDER_TYPES_KV.SR, this.ORDER_TYPES_KV.PO].includes(this.orderType) && this.quoteStatusItem.state === 7),
        ([this.ORDER_TYPES_KV.RO, this.ORDER_TYPES_KV.HO, this.ORDER_TYPES_KV.SO].includes(this.orderType) && this.quoteStatusItem.state === 8),
        ([this.ORDER_TYPES_KV.SM, this.ORDER_TYPES_KV.TO].includes(this.orderType) && this.quoteStatusItem.state === 9),
      ]
      return {
        conditioned: unpackInProgressConditions.some(Boolean) && this.quoteStatusItem.status !== 4,
        btnProps: {
          text: 'Put Back',
          icon: 'LBoxSentIcon',
          action: 'putBack',
          disabled: !this.orderItems.every(item => item.received_at),
        },
      }
    },
    putBackStatus() {
      const putBackConditions = [
        ([this.ORDER_TYPES_KV.SR, this.ORDER_TYPES_KV.PO].includes(this.orderType) && this.quoteStatusItem.state === 8),
        ([this.ORDER_TYPES_KV.RO, this.ORDER_TYPES_KV.HO, this.ORDER_TYPES_KV.SO].includes(this.orderType) && this.quoteStatusItem.state === 9),
        ([this.ORDER_TYPES_KV.SM, this.ORDER_TYPES_KV.TO].includes(this.orderType) && this.quoteStatusItem.state === 10),
      ]
      return {
        conditioned: putBackConditions.some(Boolean) && this.quoteStatusItem.status !== 4,
        btnProps: {
          text: 'Finish',
          icon: '',
          action: 'finishInbound',
          disabled: !this.orderItems.every(item => item.inspected_inbound && item.put_backed_at),
          isPrint: true,
        },
      }
    },
    finishedStatus() {
      const inspectConditions = [
        ([this.ORDER_TYPES_KV.SR, this.ORDER_TYPES_KV.PO].includes(this.orderType) && this.quoteStatusItem.state === 8),
        ([this.ORDER_TYPES_KV.RO, this.ORDER_TYPES_KV.HO, this.ORDER_TYPES_KV.SO].includes(this.orderType) && this.quoteStatusItem.state === 9),
        ([this.ORDER_TYPES_KV.SM, this.ORDER_TYPES_KV.TO].includes(this.orderType) && this.quoteStatusItem.state === 10),
      ]
      return {
        conditioned: inspectConditions.some(Boolean) && this.quoteStatusItem.status === 4,
        btnProps: {
          text: 'Finish',
          icon: '',
          action: '',
          disabled: true,
          isPrint: true,
        },
      }
    },
    btnProps() {
      const orderStatus = [
        this.dispatchStatus,
        this.receiveStatus,
        this.unpackStatus,
        this.putBackStatus,
        this.finishedStatus,
      ]
      const condition = _.find(orderStatus, item => item.conditioned)
      return condition?.btnProps || { text: '', icon: '', action: null }
    },
  },
  methods: {
    handleCheckBySerialNumber(serialNumber) {
      const val = serialNumber.trim()
      const checkedItems = _.find(this.orderItems, item => ((item?.inventory_item && item?.inventory_item?.serial_number === val) || item?.product?.sku === val))
      if (!checkedItems) return
      this.orderItem.order_items = this.orderItems.map(item => (item.id === checkedItems.id ? {
        ...item,
        received_at: item?.received_at ? null : new Date().toString(),
      } : item))
    },
    updateItem(item) {
      this.orderItem.order_items = this.orderItems.map(orderItem => (orderItem.id === item.id ? item : orderItem))
    },
    reportedAsset() {
      this.getOrderItem(this)
    },
  },
  setup() {
    const { MODULE_NAME, getOrderItem } = config()
    const { quoteStatusItemsMerge } = warehouseConfig()
    const { ORDER_TYPES_KV } = mainOrderConfig()

    return {
      MODULE_NAME,
      quoteStatusItemsMerge,
      getOrderItem,
      ORDER_TYPES_KV,
    }
  },
}
</script>
